import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../../config/serviceEndpoints';
import staticGateway from '../../../../config/service';

export const verifyCode = createAsyncThunk('auth/verify', async (data, { rejectWithValue }) => {
    try {
        const response = await staticGateway.get(
            `${serviceEndpoints.verifycode}?email=${data?.email}&code=${data.code}`
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const resetPwd = createAsyncThunk('auth/reset', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await staticGateway.post(serviceEndpoints.resetpwd, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'resetpwd',
    initialState: {
        resMsg: '',
        isLoading: false,
        redirectback: false,
        token: '',
        pwdsuccess: false,
        pwdfailed: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(verifyCode.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.redirectback = false;
                state.token = '';
                state.pwdsuccess = false;
                state.pwdfailed = false;
            })
            .addCase(verifyCode.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload;
                state.redirectback = true;
                state.token = '';
                state.pwdsuccess = false;
                state.pwdfailed = false;
            })
            .addCase(verifyCode.fulfilled, (state, { payload }) => {
                const { message, data } = payload;
                state.isLoading = false;
                state.redirectback = false;
                state.resMsg = message;
                state.token = data?.token;
                state.pwdsuccess = false;
                state.pwdfailed = false;
            })
            .addCase(resetPwd.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.redirectback = false;
                state.pwdsuccess = false;
                state.pwdfailed = false;
            })
            .addCase(resetPwd.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload;
                state.redirectback = false;
                state.pwdsuccess = false;
                state.pwdfailed = true;
            })
            .addCase(resetPwd.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.isLoading = false;
                state.redirectback = false;
                state.resMsg = message;
                state.pwdsuccess = success;
                state.pwdfailed = false;
            });
    },
});

export default slice.reducer;
