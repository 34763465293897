import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import '../../assets/scss/dashboard.scss';
import '../../assets/scss/header.scss';
import '../../assets/scss/side-nav.scss';
import '../../assets/scss/common.scss';
import { logoutUser } from '../auth/Login/redux';
import { sidebarMenus } from './SidebarConfig';
import logoutIcon from '../../assets/img/Sidenav_menu_icon/logout.svg';

export default function Sidebar({ close }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    return (
        <div className={`side-nav-wrapper ${close && 'isactive'}`}>
            <div className="side-navigation custom-scroll">
                <ul className="navbar-nav ">
                    {sidebarMenus.map(({ name, icon, path }) => (
                        <li className="nav-item" onClick={() => navigate(`${path}`)} key={`${name}-key`}>
                            <a
                                className={`nav-link ${
                                    pathname === path ||
                                    (pathname.startsWith('/court-management') && path === '/court-management')
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <figure>
                                    <img src={icon} alt="dashboardimg" />
                                </figure>
                                <span className="indication">{name}</span>
                            </a>
                        </li>
                    ))}
                </ul>
                <div className="nav-footer">
                    <ul className="navbar-nav ">
                        <li className="nav-item" onClick={() => dispatch(logoutUser())}>
                            <a className="nav-link">
                                <figure>
                                    <img src={logoutIcon} alt="Logout" />
                                </figure>
                                <span className="indication">Logout</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
