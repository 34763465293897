import React from 'react';

export const components = {
    signIn: React.lazy(() => import('../components/auth/Login')),
    page404: React.lazy(() => import('../components/common/Page404')),
    resetPassword: React.lazy(() => import('../components/auth/ResetPassword')),
    forgotPassword: React.lazy(() => import('../components/auth/ForgotPassword')),
    dashboard: React.lazy(() => import('../components/dashboard')),
    userManagement: React.lazy(() => import('../components/userManagement')),
    userInfo: React.lazy(() => import('../components/userManagement/UserInfo')),
    trainerManagement: React.lazy(() => import('../components/trainerManagement')),
    trainerInfo: React.lazy(() => import('../components/trainerManagement/TrainerInfo')),
    leagueAdminManagement: React.lazy(() => import('../components/leagueAdminManagement')),
    leagueAdminInfo: React.lazy(() => import('../components/leagueAdminManagement/leagueAdminInfo')),
    leagueManagement: React.lazy(() => import('../components/leagueManagement')),
    leagueDetails: React.lazy(() => import('../components/leagueManagement/leagueInfo')),
    courtCreation: React.lazy(() => import('../components/courtCreation')),
    courtCreationInfo: React.lazy(() => import('../components/courtCreation/courtInfo')),
    reportsAndSummary: React.lazy(() => import('../components/reportAndSummary')),
    mediaManagement: React.lazy(() => import('../components/mediaManagement')),
    mediaInfo: React.lazy(() => import('../components/mediaManagement/mediaInfo')),
    sportsManagement: React.lazy(() => import('../components/sportsManagement')),
    hashtag: React.lazy(() => import('../components/hashagManagement')),
    myAccount: React.lazy(() => import('../components/myAccount')),
    createCourt: React.lazy(() => import('../components/courtCreation/createCourt')),
};
