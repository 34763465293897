import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';
import { getQueryParams } from '../../../utils/utils';

export const getUserList = createAsyncThunk('/api/superAdmin/getUser', async (data = {}, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.get(
            `${serviceEndpoints.getTableData}/Player?${getQueryParams(data)}`,
            body
        );
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deleteRow = createAsyncThunk('/api/superAdmin/deleteUser', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.delete(`${serviceEndpoints.deleteTableRow}/${data}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'user',
    initialState: {
        resMsg: '',
        userList: {},
        userData: {},
        isLoading: false,
        isDeleted: false,
    },

    extraReducers: (builder) => {
        builder

            .addCase(getUserList.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.userData = {};
                state.isDeleted = false;
            })
            .addCase(getUserList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.userData = {};
                state.userList = {};
            })
            .addCase(getUserList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.userData = payload?.result || null;
                if (payload.result.rows) {
                    state.userList = payload.result.rows;
                } else state.userList = current(state).userList;
            })
            .addCase(deleteRow.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
            })
            .addCase(deleteRow.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = false;
            })
            .addCase(deleteRow.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = payload?.statusCode === 200 && payload.message;
            });
    },
});

export default slice.reducer;
