import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../../config/serviceEndpoints';
import staticGateway from '../../../../config/service';

export const forgetPwd = createAsyncThunk('auth/forget', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await staticGateway.post(serviceEndpoints.forgot, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }

        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'forgetpwd',
    initialState: {
        resMsg: '',
        isLoading: false,
        redirect: false,
        forgetfail: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(forgetPwd.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.redirect = false;
                state.forgetfail = false;
            })
            .addCase(forgetPwd.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload;
                state.redirect = false;
                state.forgetfail = true;
            })
            .addCase(forgetPwd.fulfilled, (state, { payload }) => {
                const { success, message } = payload;
                state.isLoading = false;
                state.resMsg = message;
                state.redirect = success;
                state.forgetfail = false;
            });
    },
});

export default slice.reducer;
