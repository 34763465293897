import React from 'react';
import { components } from './component';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const RouteStructure = () => {
    const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
    const isLoggedIn = !!token;
    return [
        {
            path: '/',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.signIn />
                </PublicRoute>
            ),
        },
        {
            path: '/forgotpassword',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.forgotPassword />
                </PublicRoute>
            ),
        },
        {
            path: '/resetpassword',
            element: (
                <PublicRoute isLoggedIn={isLoggedIn}>
                    <components.resetPassword />
                </PublicRoute>
            ),
        },
        {
            path: '/dashboard',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.dashboard />
                </PrivateRoute>
            ),
        },
        {
            path: '/user-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.userManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/user-info/:userId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.userInfo />
                </PrivateRoute>
            ),
        },
        {
            path: '/trainer-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.trainerManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/trainer-info/:trainerId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.trainerInfo />
                </PrivateRoute>
            ),
        },
        {
            path: '/league-admin-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.leagueAdminManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/league-admin-info/:adminId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.leagueAdminInfo />
                </PrivateRoute>
            ),
        },
        {
            path: '/league-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.leagueManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/league-info/:leagueId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.leagueDetails />
                </PrivateRoute>
            ),
        },
        {
            path: '/court-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.courtCreation />
                </PrivateRoute>
            ),
        },
        {
            path: '/court-management-map',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.createCourt />
                </PrivateRoute>
            ),
        },
        {
            path: '/court-management-info/:courtId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.courtCreationInfo />
                </PrivateRoute>
            ),
        },
        {
            path: '/reports-and-summary',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.reportsAndSummary />
                </PrivateRoute>
            ),
        },
        {
            path: '/sports-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.sportsManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/hashtag-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.hashtag />
                </PrivateRoute>
            ),
        },
        {
            path: '/media-management',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.mediaManagement />
                </PrivateRoute>
            ),
        },
        {
            path: '/media-management-info/:userId',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.mediaInfo />
                </PrivateRoute>
            ),
        },
        {
            path: '/my-account/:type',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.myAccount />
                </PrivateRoute>
            ),
        },
        {
            path: '*',
            element: (
                <PrivateRoute isLoggedIn={isLoggedIn}>
                    <components.page404 />
                </PrivateRoute>
            ),
        },
    ];
};

export default RouteStructure;
