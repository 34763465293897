import moment from 'moment';
import { toast } from 'react-toastify';

const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,9}$/im;
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (val) => emailRegex.test(String(val).toLowerCase());

export const validatePhone = (val) => phoneRegex.test(val);

export const formatDateStructure = (date) => (date && moment(date).format('MM-DD-YYYY')) || 'N/A';

export const signupDateCalculator = (subDays) => moment().subtract(subDays, 'days').format('YYYY-MM-DD');

export const getQueryParams = (data) => {
    let queryString = '';
    const params = Object.keys(data);
    params.map((param) => {
        if (data[param]) queryString += `&${param}=${data[param]}`;
        return queryString;
    });
    return queryString;
};
const toastConfig = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    pauseOnFocusLoss: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    toastId: Math.random(),
};
// info, success, warning, error, default
export const Notifications = (message = null, type = 'default') => {
    if (!message) return;
    toast(message, { ...toastConfig, type });
};
