const serviceEndpoints = {
    login: '/auth/login',
    profile: '/auth/profile',
    getReport: '/superAdmin/report',
    getExportReportUrl: '/superAdmin/report/download',
    getTableData: '/superAdmin/user',
    deleteTableRow: '/superAdmin/user',
    forgot: '/auth/forget-password/verify/email',
    verifycode: '/auth/forget-password/verify/code',
    resetpwd: '/auth/forget-password/reset/password',
    dashboard: '/superAdmin/dashboard',
    court: '/superAdmin/court',
    getLeagues: '/superAdmin/leagueManagement',
    getPost: '/superAdmin/media/post',
    hashtag: '/superAdmin/hashtag',
    getStory: '/superAdmin/media/story',
    getUserMedia: '/superAdmin/media/users',
    notifications: '/notification',
    sports: '/superAdmin/sports',
};

export default serviceEndpoints;
