import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';
import { getQueryParams } from '../../../utils/utils';

export const clearErrorMessage = createAction('clearErrorMessage');

export const getCourtList = createAsyncThunk('/api/superAdmin/courts', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.court}?${getQueryParams(data)}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getCourtDetails = createAsyncThunk(
    '/api/superAdmin/getCourtDetails',
    async (data, { rejectWithValue }) => {
        const body = { ...data, platform: 'web' };
        try {
            const response = await staticGateway.get(`${serviceEndpoints.court}/${data}`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const registerCourt = createAsyncThunk('/api/superAdmin/addCourt', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await staticGateway.post(`${serviceEndpoints.court}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const updateCourt = createAsyncThunk('/api/superAdmin/updateCourt', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.put(`${serviceEndpoints.court}/${data.id}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deleteCourt = createAsyncThunk('/api/superAdmin/deleteCourt', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.delete(`${serviceEndpoints.court}/${data}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'court',
    initialState: {
        resMsg: '',
        errMsg: '',
        courtData: {},
        courtsList: {},
        isLoading: false,
        isDeleted: false,
        isCreated: false,
        isUpdated: false,
        total_count: null,
    },

    extraReducers: (builder) => {
        builder

            .addCase(getCourtList.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.courtData = {};
                state.isDeleted = false;
                state.isCreated = false;
                state.isDeleted = false;
                state.isUpdated = false;
            })
            .addCase(getCourtList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.courtData = {};
                state.courtsList = {};
                state.total_count = null;
            })
            .addCase(getCourtList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.courtData = payload.result || null;
                state.courtsList = payload.result.rows;
                state.total_count = payload.result.count;
            })
            .addCase(getCourtDetails.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isCreated = false;
                state.isDeleted = false;
                state.isUpdated = false;
                state.courtData = {};
            })
            .addCase(getCourtDetails.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.courtData = {};
            })
            .addCase(getCourtDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.courtData = payload.result || null;
            })
            .addCase(updateCourt.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isUpdated = false;
            })
            .addCase(updateCourt.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isUpdated = false;
            })
            .addCase(updateCourt.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isUpdated = payload.success;
            })
            .addCase(registerCourt.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.errMsg = '';
                state.isCreated = false;
            })
            .addCase(registerCourt.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isCreated = false;
                state.resMsg = payload.message;
                state.errMsg = payload.errorMessages;
            })
            .addCase(registerCourt.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.errMsg = payload.errorMessages;
                state.isCreated = payload.success;
            })
            .addCase(deleteCourt.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
            })
            .addCase(deleteCourt.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = false;
            })
            .addCase(deleteCourt.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = payload.success;
            })
            .addCase('clearErrorMessage', (state) => {
                state.errMsg = '';
                state.resMsg = '';
                state.isCreated = false;
            });
    },
});

export default slice.reducer;
