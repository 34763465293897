import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';
import { getQueryParams } from '../../../utils/utils';

export const getUserPostList = createAsyncThunk(
    '/api/superAdmin/media/users',
    async (data = {}, { rejectWithValue }) => {
        const body = { ...data };
        try {
            const response = await staticGateway.get(`${serviceEndpoints.getUserMedia}?${getQueryParams(data)}`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const getPostList = createAsyncThunk('/api/superAdmin/getPost', async (data = {}, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.getPost}?${getQueryParams(data)}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getStoryList = createAsyncThunk('/api/superAdmin/getStory', async (data = {}, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.getStory}?${getQueryParams(data)}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deletePost = createAsyncThunk('/api/superAdmin/deletePost', async (id, { rejectWithValue }) => {
    const body = { id, platform: 'web' };
    try {
        const response = await staticGateway.delete(`${serviceEndpoints.getPost}/${id}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const deletePostMedia = createAsyncThunk(
    '/api/superAdmin/deletePostMedia',
    async ({ postId, mediaId }, { rejectWithValue }) => {
        const body = { postId, mediaId, platform: 'web' };
        try {
            const response = await staticGateway.delete(`${serviceEndpoints.getPost}/${postId}/media/${mediaId}`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const deleteStory = createAsyncThunk('/api/superAdmin/deleteStory', async (id, { rejectWithValue }) => {
    const body = { id, platform: 'web' };
    try {
        const response = await staticGateway.delete(`${serviceEndpoints.getStory}/${id}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const resetData = createAction('reset/media');
const slice = createSlice({
    name: 'user',
    initialState: {
        resMsg: '',
        postList: [],
        userMediaList: {},
        storyList: {},
        mediaPageData: {},
        postPageData: {},
        storyPageData: {},
        isDeleted: false,
        isLoading: false,
        isPostLoading: false,
        isStoryDeleted: false,
    },

    extraReducers: (builder) => {
        builder

            .addCase('reset/media', (state, { payload }) => {
                state[payload.field] = payload.value;
            })
            .addCase(getUserPostList.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
                state.mediaPageData = {};
                state.isDeleted = false;
                state.resMsg = '';
            })
            .addCase(getUserPostList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.userMediaList = {};
                state.mediaPageData = {};
            })
            .addCase(getUserPostList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.userMediaList = payload.users.rows;
                state.mediaPageData = payload.users.pagination;
            })
            .addCase(getPostList.pending, (state) => {
                state.isPostLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
                state.mediaPageData = {};
            })
            .addCase(getPostList.rejected, (state, { payload }) => {
                state.isPostLoading = false;
                state.resMsg = payload.message;
                state.postList = [];
                state.mediaPageData = {};
            })
            .addCase(getPostList.fulfilled, (state, { payload }) => {
                state.isPostLoading = false;
                state.resMsg = payload.message;
                state.postList = payload.post.rows;
                state.postPageData = payload.post.pagination;
            })
            .addCase(getStoryList.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
                state.isStoryDeleted = false;
            })
            .addCase(getStoryList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.storyList = {};
            })
            .addCase(getStoryList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.storyList = payload.story.rows;
                state.storyPageData = payload.story.pagination;
            })
            .addCase(deletePost.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
            })
            .addCase(deletePost.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = false;
            })
            .addCase(deletePost.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = payload?.statusCode === 200 && payload.message;
            })
            .addCase(deletePostMedia.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
            })
            .addCase(deletePostMedia.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = false;
            })
            .addCase(deletePostMedia.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isDeleted = payload?.statusCode === 200 && payload.message;
            })
            .addCase(deleteStory.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isStoryDeleted = false;
            })
            .addCase(deleteStory.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isStoryDeleted = false;
            })
            .addCase(deleteStory.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isStoryDeleted = payload?.statusCode === 200 && payload.message;
            });
    },
});
// Extract and export each action creator by name

export default slice.reducer;
