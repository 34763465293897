import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

import {
    getNotifications,
    readNotification,
    resetNotification,
    deleteNotification,
    getUnreadCount,
} from '../../store/defaultSlice';
import userImg from '../../assets/img/default_profile.png';
import closeImg from '../../assets/img/close_without_bg.svg';
import loaderImg from '../../assets/img/notifications-loader.gif';

export default function Notification({ show }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [limit, setLimit] = useState(10);
    const { notifyLoader, notifications, count, deleteStatus, statusUpdated } = useSelector(
        (state) => state.commonReducer
    );

    useEffect(() => {
        if (notifications.length === count) return;

        if (show) {
            dispatch(getNotifications({ limit }));
        }
    }, [limit, show]);

    useEffect(() => {
        if (deleteStatus) dispatch(getNotifications({ limit }));
    }, [deleteStatus]);

    useEffect(() => {
        if (statusUpdated) {
            dispatch(getNotifications({ limit }));
            dispatch(getUnreadCount({ limit: null }));
        }
    }, [statusUpdated]);

    useEffect(
        () => () => {
            dispatch(resetNotification());
        },
        []
    );

    const handleReadNotifications = (NotificationId) => {
        if (NotificationId) {
            dispatch(readNotification({ NotificationId }));
        } else dispatch(readNotification({ NotificationId: [] }));
    };

    const handleAllNotification = async () => {
        await dispatch(getNotifications({ limit: null }));
    };

    const handleScroll = ({ target }) => {
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            setLimit(limit + 10);
        }
    };

    const handleDelete = (NotificationId) => {
        dispatch(deleteNotification({ id: NotificationId }));
    };

    const navigateFn = (role, id) => {
        switch (role) {
            case 'Player':
                return `/user-info/${id}`;
            case 'LeagueAdmin':
                return `/league-admin-info/${id}`;
            case 'Trainer':
                return `/trainer-info/${id}`;
            default:
                break;
        }
    };

    return (
        <div className={`dropdown-menu ${show && 'show'}`}>
            <div className="notification-header">
                <h4 className="notification-header-title">Notifications</h4>
                <span
                    className="notification-header-mark custom-cursor-pointer"
                    onClick={() => handleReadNotifications()}
                    style={{ cursor: 'pointer' }}
                >
                    Mark all as read
                </span>
            </div>
            <div className="notification-body custom-scroll" onScroll={handleScroll}>
                <ul>
                    {notifications &&
                        notifications?.length > 0 &&
                        notifications?.map((data, inx) => (
                            <li key={`${Math.random() + inx}`} className={data.is_read ? '' : 'unread'}>
                                <a className="dropdown-item" style={{ cursor: 'auto' }}>
                                    <div className="list">
                                        <div className="userIcon">
                                            <img
                                                src={
                                                    data?.notification?.meta?.user?.photo ||
                                                    data?.notification?.meta?.league?.photo ||
                                                    userImg
                                                }
                                                className="user-img"
                                                alt=""
                                            />
                                        </div>
                                        <div className="details">
                                            <h4>
                                                {data?.notification?.title}{' '}
                                                <small style={{ marginLeft: '10px' }}>
                                                    {moment(data?.created_at).fromNow()}
                                                </small>
                                                <img
                                                    src={closeImg}
                                                    className="notfi-close"
                                                    alt="close"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleDelete(data.notification_id);
                                                    }}
                                                />
                                            </h4>
                                            <p>{data?.notification?.message || ''}</p>
                                            {data?.notification?.meta?.user ? (
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleReadNotifications(data.notification_id);

                                                        navigate(
                                                            navigateFn(
                                                                data?.notification?.meta?.user?.role,
                                                                data?.notification?.meta?.user?.id
                                                            )
                                                        );
                                                    }}
                                                >
                                                    View Profile
                                                </span>
                                            ) : (
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        handleReadNotifications(data.notification_id);

                                                        navigate(
                                                            `/league-info/${data?.notification?.meta?.league?.id}`
                                                        );
                                                    }}
                                                >
                                                    View Details
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                </ul>
                {notifyLoader && (
                    <div className="notification-loading">
                        <img src={loaderImg} alt="" />
                    </div>
                )}
            </div>
            <div className="notification-footer">
                <span className="view-all" style={{ cursor: 'pointer' }} onClick={() => handleAllNotification()}>
                    View All Notifications
                </span>
            </div>
        </div>
    );
}
