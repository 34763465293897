import axios from 'axios';
import API_ROUTE from './serviceConstants';
import { Notifications } from '../utils/utils';

const staticGateway = axios.create({
    baseURL: API_ROUTE,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 20000,
});

staticGateway.interceptors.response.use(
    (response) => response,
    ({ response }) => {
        if (response?.data?.statusCode === 400) {
            Notifications(response?.data?.message, 'error');
            return response;
        }
        if (response?.data?.statusCode === 401) {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = '/';
        }
    }
);

staticGateway.interceptors.request.use((config) => {
    let token;
    if (localStorage.getItem('rememberMe')) {
        token = localStorage.getItem('access_token');
    } else token = sessionStorage.getItem('access_token');
    if (token) config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    return config;
});
export default staticGateway;
