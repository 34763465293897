import React, { useState, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { AdminContext } from '../context';
import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import '../assets/scss/header.scss';
import '../assets/scss/side-nav.scss';
import '../assets/scss/common.scss';

function PrivateRoute({ children, isLoggedIn }) {
    const [barStat, setBarStat] = useState(false);

    const valueProvider = useMemo(() => ({ barStat }), [barStat]);

    return isLoggedIn ? (
        <>
            <Header close={barStat} setBarFn={setBarStat} />
            <div className="wrapper">
                <Sidebar close={barStat} />
                <AdminContext.Provider value={valueProvider}>{children}</AdminContext.Provider>
            </div>
        </>
    ) : (
        <Navigate to="/" />
    );
}

export default PrivateRoute;
