import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from '../components/auth/Login/redux';
import commonReducer from './defaultSlice';
import forgetReducer from '../components/auth/ForgotPassword/redux';
import verifyReducer from '../components/auth/ResetPassword/redux';
import userManagementReducer from '../components/userManagement/redux';
import leagueAdminReducer from '../components/leagueAdminManagement/redux';
import leagueManagementReducer from '../components/leagueManagement/redux';
import reportsAndSummaryReducer from '../components/reportAndSummary/redux';
import trainerManagementReducer from '../components/trainerManagement/redux';
import dashboardReducer from '../components/dashboard/redux';
import profileReducer from '../components/myAccount/redux';
import courtReducer from '../components/courtCreation/redux';
import mediaReducer from '../components/mediaManagement/redux';
import sportsReducer from '../components/sportsManagement/redux';
import hashtagReducer from '../components/hashagManagement/redux';

const rootReducer = combineReducers({
    loginReducer,
    profileReducer,
    forgetReducer,
    verifyReducer,
    commonReducer,
    leagueAdminReducer,
    userManagementReducer,
    leagueManagementReducer,
    trainerManagementReducer,
    dashboardReducer,
    courtReducer,
    reportsAndSummaryReducer,
    mediaReducer,
    sportsReducer,
    hashtagReducer,
});

export default rootReducer;
