import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import serviceEndpoints from '../config/serviceEndpoints';
import staticGateway from '../config/service';
import { getQueryParams } from '../utils/utils';

export const startLoader = createAction('loader/start');
export const endLoader = createAction('loader/end');
export const resetNotification = createAction('notification/reset');

export const getNotifications = createAsyncThunk('/api/superAdmin/Notifications', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.notifications}?${getQueryParams(data)}`, body);

        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
export const getUnreadCount = createAsyncThunk('/api/superAdmin/unreadcount', async (data, { rejectWithValue }) => {
    const body = { ...data };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.notifications}?${getQueryParams(data)}`, body);

        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const readNotification = createAsyncThunk(
    '/api/superAdmin/readNotification',
    async (data, { rejectWithValue }) => {
        const body = { ...data };
        try {
            const response = await staticGateway.post(serviceEndpoints.notifications, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const deleteNotification = createAsyncThunk(
    '/api/superAdmin/deleteNotification',
    async (data, { rejectWithValue }) => {
        try {
            const response = await staticGateway.delete(`${serviceEndpoints.notifications}?NotificationId=${data?.id}`);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

const slice = createSlice({
    name: 'common',
    initialState: {
        isLoading: false,
        notifications: {},
        statusUpdated: false,
        deleteStatus: false,
        notifyLoader: false,
        count: 0,
        unread: 0,
    },

    extraReducers: (builder) => {
        builder
            .addCase('loader/start', (state) => {
                state.isLoading = true;
            })
            .addCase('loader/end', (state) => {
                state.isLoading = false;
            })
            .addCase('notification/reset', (state) => {
                state.notifyLoader = false;
                state.notifications = {};
                state.count = 0;
                state.statusUpdated = false;
                state.deleteStatus = false;
            })
            .addCase(getNotifications.pending, (state) => {
                state.notifyLoader = true;
                state.statusUpdated = false;
            })
            .addCase(getNotifications.rejected, (state) => {
                state.notifyLoader = false;
                state.notifications = {};
                state.count = 0;
            })
            .addCase(getNotifications.fulfilled, (state, { payload }) => {
                state.notifyLoader = false;
                state.notifications = payload?.result?.notification?.rows;
                state.count = payload?.result?.notification?.count;
            })
            .addCase(readNotification.pending, (state) => {
                state.statusUpdated = false;
                state.notifyLoader = true;
            })
            .addCase(readNotification.rejected, (state) => {
                state.statusUpdated = false;
                state.notifyLoader = false;
            })
            .addCase(readNotification.fulfilled, (state, { payload }) => {
                state.statusUpdated = payload?.success;
                state.notifyLoader = false;
            })
            .addCase(deleteNotification.pending, (state) => {
                state.deleteStatus = false;
                state.notifyLoader = true;
            })
            .addCase(deleteNotification.rejected, (state) => {
                state.deleteStatus = false;
                state.notifyLoader = false;
            })
            .addCase(deleteNotification.fulfilled, (state, { payload }) => {
                state.deleteStatus = payload?.success;
                state.notifyLoader = false;
            })
            .addCase(getUnreadCount.rejected, (state) => {
                state.unread = 0;
            })
            .addCase(getUnreadCount.fulfilled, (state, { payload }) => {
                state.unread = payload?.result?.unReadMeassage?.count;
            });
    },
});

export default slice.reducer;
