import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUnreadCount } from '../../store/defaultSlice';
import Notification from './Notification';
import logoImg from '../../assets/img/logo.svg';
import logoMobImg from '../../assets/img/logo_mob.svg';
import hamImg from '../../assets/img/icons/ham_menu.svg';
import searchImg from '../../assets/img/icons/search.svg';
import userImg from '../../assets/img/default_profile.png';
import notifyBellImg from '../../assets/img/icons/notification_bell.svg';

import '../../assets/scss/dashboard.scss';
import '../../assets/scss/header.scss';
import '../../assets/scss/side-nav.scss';
import '../../assets/scss/common.scss';

export default function Header({ close, setBarFn }) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [click, setClick] = useState(false);
    const userDetails =
        JSON.parse(localStorage.getItem('userDetails')) || JSON.parse(sessionStorage.getItem('userDetails'));

    const { unread } = useSelector((state) => state.commonReducer);

    const triggerFn = () => setClick(!click);

    useEffect(() => {
        if (click === true) setClick(false);
        dispatch(getUnreadCount({ limit: null }));
    }, [location]);

    return (
        <div className="dashboard-header">
            <div className={`side-nav-header ${close && 'isactive'}`}>
                <a href="#" className="logo ">
                    <img src={logoImg} className="web-logo" alt="logo" />
                    <img src={logoMobImg} className="mob-logo" alt="logo" />
                </a>
                <button className="btn sidebarCollapse" type="button" onClick={() => setBarFn(!close)}>
                    <img src={hamImg} alt="ham_menu" />
                </button>
            </div>
            <div className="dashboard-header-right">
                <div className="container-fluid">
                    <div className="header-section">
                        <div className="search-box">
                            <input type="text" className="form-control" placeholder="Search for anything…" />
                            <div className="search-box-icon">
                                <button className="btn" type="button">
                                    <img src={searchImg} alt="search " />
                                </button>
                            </div>
                        </div>
                        <div className="user-area">
                            <div className="notification-header dropdown">
                                <button
                                    className="btn"
                                    type="button"
                                    onClick={() => {
                                        triggerFn();
                                    }}
                                >
                                    <img src={notifyBellImg} alt="notification" />
                                    <span className="badge badge-secondary">{unread || 0}</span>
                                </button>
                                {click && <Notification show={click} />}
                            </div>

                            <div className="user-header">
                                <div className="user-txt">
                                    <div className="dropdown">
                                        <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown">
                                            <img src={userDetails.photo || userImg} alt="user" />
                                            <h4>
                                                {userDetails?.name} <span>Administrator</span>
                                            </h4>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <a
                                                    className="dropdown-item"
                                                    href="#"
                                                    onClick={() => navigate('/my-account/view')}
                                                >
                                                    My Account
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
