import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, Zoom } from 'react-toastify';
import RoutesIndex from './routes/Index';
import Loader from './components/common/Loader';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/common.scss';

function App() {
    const { isLoading } = useSelector((state) => state?.commonReducer);
    return (
        <>
            <ToastContainer closeButton transition={Zoom} icon theme="dark" hideProgressBar />
            <Suspense>
                <Router>
                    {isLoading && <Loader />}
                    <RoutesIndex />
                </Router>
            </Suspense>
        </>
    );
}

export default App;
