import React from 'react';
import loaderGif from '../../assets/img/loader.gif';

export default function Loader() {
    return (
        <div className="loading-wrapper">
            <img className="loading-img" src={loaderGif} alt="loader" />
        </div>
    );
}
