import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';
// import { getQueryParams } from '../../../utils/utils';

export const getProfile = createAsyncThunk('/api/superAdmin/getProfile', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.profile}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const updateProfile = createAsyncThunk('/api/superAdmin/updateProfile', async (data, { rejectWithValue }) => {
    const body = data;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await staticGateway.patch(`${serviceEndpoints.profile}`, body, { headers });
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const changePassword = createAsyncThunk('/api/superAdmin/changePassword', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.put(`${serviceEndpoints.profile}/password`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

const slice = createSlice({
    name: 'profile',
    initialState: {
        resMsg: '',
        profileData: {},
        isLoading: false,
        isUpdated: false,
        isPwdUpdated: false,
        total_count: null,
    },

    extraReducers: (builder) => {
        builder

            .addCase(getProfile.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.profileData = {};
                state.isUpdated = false;
                state.isPwdUpdated = false;
            })
            .addCase(getProfile.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.profileData = {};
                state.total_count = null;
                state.resMsg = payload.message;
            })
            .addCase(getProfile.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.profileData = payload?.data || null;
                localStorage.setItem('userDetails', JSON.stringify(payload?.data));
            })
            .addCase(updateProfile.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isUpdated = false;
            })
            .addCase(updateProfile.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.isUpdated = false;
                state.resMsg = payload.message;
            })
            .addCase(updateProfile.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isUpdated = payload.success;
            })
            .addCase(changePassword.pending, (state) => {
                state.resMsg = '';
                state.isLoading = true;
                state.isPwdUpdated = false;
            })
            .addCase(changePassword.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isPwdUpdated = false;
            })
            .addCase(changePassword.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isPwdUpdated = payload.success || false;
            });
    },
});

export default slice.reducer;
