import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';

export const getSportsList = createAsyncThunk(
    '/api/superAdmin/sports',
    async ({ url = serviceEndpoints.sports, params }) => {
        try {
            const { data } = await staticGateway.get(url, { params });
            const { success } = data;
            if (success) {
                return data;
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const CreateSports = createAsyncThunk(
    '/api/superAdmin/addSports',
    async ({ url = serviceEndpoints.sports, value }) => {
        try {
            const { data } = await staticGateway.post(url, value);
            const { success } = data;
            if (success) {
                return data;
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const updateSport = createAsyncThunk(
    '/api/superAdmin/updateSport',
    async ({ url = serviceEndpoints.sports, value }) => {
        try {
            const body = { name: value.name };
            const { data } = await staticGateway.put(`${url}/${value.id}`, body);
            const { success } = data;
            if (success) {
                return data;
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const deleteSport = createAsyncThunk(
    '/api/superAdmin/deleteSport',
    async ({ url = serviceEndpoints.sports, params }) => {
        try {
            const { data } = await staticGateway.delete(`${url}/${params}`);
            const { success } = data;
            if (success) {
                return data;
            }
        } catch (error) {
            return error.message;
        }
    }
);

const sportsSlice = createSlice({
    name: 'sports',
    initialState: {
        resMsg: '',
        sportsData: {},
        sportsList: {},
        isLoading: false,
        isDeleted: false,
        isCreated: false,
        isUpdated: false,
        total_count: null,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getSportsList.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.sportsData = {};
                state.isDeleted = false;
                state.isCreated = false;
                state.isUpdated = false;
            })
            .addCase(getSportsList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.sportsData = {};
                state.sportsList = {};
                state.total_count = null;
            })
            .addCase(getSportsList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.sportsData = payload?.result || null;
                state.sportsList = payload?.result?.rows;
                state.total_count = payload?.result?.count;
            })
            .addCase(updateSport.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isUpdated = false;
            })
            .addCase(updateSport.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isUpdated = false;
            })
            .addCase(updateSport.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isUpdated = payload?.success;
            })
            .addCase(CreateSports.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isCreated = false;
            })
            .addCase(CreateSports.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isCreated = false;
            })
            .addCase(CreateSports.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isCreated = payload?.success;
            })
            .addCase(deleteSport.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
            })
            .addCase(deleteSport.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isDeleted = false;
            })
            .addCase(deleteSport.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isDeleted = payload?.success;
            });
    },
});

export default sportsSlice.reducer;
