import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';
import { getQueryParams } from '../../../utils/utils';

export const resetMsg = createAction('resetMsg');

export const getReports = createAsyncThunk('/api/superAdmin/report', async (data = {}, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.getReport}?${getQueryParams(data)}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const getExportReportUrl = createAsyncThunk(
    '/api/superAdmin/report/download',
    async (data = {}, { rejectWithValue }) => {
        const headers = {
            'x-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        try {
            const response = await staticGateway.get(`${serviceEndpoints.getExportReportUrl}?${getQueryParams(data)}`, {
                headers,
            });
            const { success, message } = response.data;
            const baseUrl = process.env.REACT_APP_API_ENDPOINT;
            if (success) {
                window.open(`${baseUrl}/${response.data.data.url}`);
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

const slice = createSlice({
    name: 'report',
    initialState: {
        resMsg: '',
        reportData: {},
        reports: {},
        exportUrl: '',
        isLoading: false,
        isDeleted: false,
        downloadMsg: '',
    },

    extraReducers: (builder) => {
        builder
            .addCase(getReports.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.downloadMsg = '';
                state.reportData = {};
                state.isDeleted = false;
            })
            .addCase(getReports.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.reports = {};
                state.reportData = {};
            })
            .addCase(getReports.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.reports = payload?.data?.rows;
                state.reportData = payload?.data || {};
            })
            .addCase(getExportReportUrl.pending, (state) => {
                state.isLoading = true;
                state.downloadMsg = '';
                state.exportUrl = {};
            })
            .addCase(getExportReportUrl.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.downloadMsg = payload.message;
                state.exportUrl = {};
            })
            .addCase(getExportReportUrl.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.downloadMsg = payload.message;
                state.exportUrl = payload?.data?.url;
            })
            .addCase('resetMsg', (state) => {
                state.resMsg = '';
                state.downloadMsg = '';
            });
    },
});

export default slice.reducer;
