import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';
import { getQueryParams } from '../../../utils/utils';

export const getLeagueList = createAsyncThunk('/api/superAdmin/getLeagues', async (data, { rejectWithValue }) => {
    const body = { ...data, platform: 'web' };
    try {
        const response = await staticGateway.get(`${serviceEndpoints.getLeagues}?${getQueryParams(data)}`, body);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});
export const getLeagueInfo = createAsyncThunk('/api/superAdmin/getLeagueInfo', async (data, { rejectWithValue }) => {
    try {
        const response = await staticGateway.get(`${serviceEndpoints.getLeagues}/${data?.leagueId}`);
        const { success, message } = response.data;
        if (success) {
            return response?.data;
        }
        return rejectWithValue(message);
    } catch (error) {
        return error.message;
    }
});

export const updateLeagueStatus = createAsyncThunk(
    '/api/superAdmin/updateLeagueStatus',
    async (data, { rejectWithValue }) => {
        const body = { ...data, platform: 'web' };
        try {
            const response = await staticGateway.put(`${serviceEndpoints.getLeagues}/${data?.leagueId}`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

export const getLeagueReviews = createAsyncThunk(
    '/api/superAdmin/getLeagueReviews',
    async (data, { rejectWithValue }) => {
        const body = { ...data, platform: 'web' };
        try {
            const response = await staticGateway.get(`${serviceEndpoints.getLeagues}/${data?.leagueId}/review`, body);
            const { success, message } = response.data;
            if (success) {
                return response?.data;
            }
            return rejectWithValue(message);
        } catch (error) {
            return error.message;
        }
    }
);

const slice = createSlice({
    name: 'leagues',
    initialState: {
        resMsg: '',
        leagueList: {},
        leagueData: {},
        reviews: {},
        isLoading: false,
        isStatusUpdated: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(getLeagueList.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.leagueData = {};
                state.isStatusUpdated = false;
            })
            .addCase(getLeagueList.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.leagueList = {};
                state.leagueData = {};
            })
            .addCase(getLeagueList.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.leagueList = payload.result.rows;
                state.leagueData = payload?.result || null;
            })
            .addCase(getLeagueInfo.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.leagueData = {};
                state.isStatusUpdated = false;
            })
            .addCase(getLeagueInfo.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.leagueData = {};
            })
            .addCase(getLeagueInfo.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.leagueData = payload?.league || null;
                state.reviews = payload?.league.reviews.rows || null;
            })
            .addCase(getLeagueReviews.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.reviews = {};
            })
            .addCase(getLeagueReviews.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.reviews = {};
            })
            .addCase(getLeagueReviews.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.reviews = payload?.rows || null;
            })
            .addCase(updateLeagueStatus.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isStatusUpdated = false;
            })
            .addCase(updateLeagueStatus.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isStatusUpdated = false;
            })
            .addCase(updateLeagueStatus.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload.message;
                state.isStatusUpdated = payload.success;
            });
    },
});

export default slice.reducer;
