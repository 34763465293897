import dashIcon from '../../assets/img/Sidenav_menu_icon/dashboard.svg';
import userMngIcon from '../../assets/img/Sidenav_menu_icon/user_management.svg';
import trainerMngIcon from '../../assets/img/Sidenav_menu_icon/trainer_management.svg';
import leagueMngIcon from '../../assets/img/Sidenav_menu_icon/league_management.svg';
import leagueAdmnMngIcon from '../../assets/img/Sidenav_menu_icon/league_admin_management.svg';
import courtCreateIcon from '../../assets/img/Sidenav_menu_icon/court_creation.svg';
import mediaMngIcon from '../../assets/img/Sidenav_menu_icon/media_management.svg';
import reportMngIcon from '../../assets/img/Sidenav_menu_icon/reports_and_summary.svg';
import sportsMngIcon from '../../assets/img/Sidenav_menu_icon/sports.svg';
import hashtagMngIcon from '../../assets/img/Sidenav_menu_icon/hastag.svg';

export const sidebarMenus = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: dashIcon,
    },
    {
        path: '/user-management',
        name: 'Player User management',
        icon: userMngIcon,
    },
    {
        path: '/trainer-management',
        name: 'Trainer Management',
        icon: trainerMngIcon,
    },
    {
        path: '/league-management',
        name: 'League Management',
        icon: leagueMngIcon,
    },
    {
        path: '/league-admin-management',
        name: 'League Admin Management',
        icon: leagueAdmnMngIcon,
    },
    {
        path: '/court-management',
        name: 'Court Management',
        icon: courtCreateIcon,
    },
    {
        path: '/sports-management',
        name: 'Sports Management',
        icon: sportsMngIcon,
    },
    {
        path: '/hashtag-management',
        name: 'Hashtag Management',
        icon: hashtagMngIcon,
    },
    {
        path: '/media-management',
        name: 'Media management',
        icon: mediaMngIcon,
    },
    {
        path: '/reports-and-summary',
        name: 'Reports & Summary',
        icon: reportMngIcon,
    },
];
