import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import serviceEndpoints from '../../../config/serviceEndpoints';
import staticGateway from '../../../config/service';

export const fetchHashtags = createAsyncThunk('fetchHashtags', async ({ url = serviceEndpoints.hashtag, params }) => {
    try {
        const { data } = await staticGateway.get(url, { params });
        if (data.success) {
            return data;
        }
    } catch (error) {
        return error.message;
    }
});

export const hashtagCreation = createAsyncThunk(
    'hashtagCreation',
    async ({ url = serviceEndpoints.hashtag, hasgtagData }) => {
        try {
            const { data } = await staticGateway.post(url, hasgtagData);
            if (data.success) {
                return data;
            }
        } catch (error) {
            return error.message;
        }
    }
);
export const deleteHashtags = createAsyncThunk('deleteHashtags', async (hashtagData) => {
    try {
        const { data } = await staticGateway.delete(`${serviceEndpoints.hashtag}/${hashtagData}`, hashtagData);
        if (data.success) {
            return data;
        }
    } catch (error) {
        return error.message;
    }
});

const hashtagSlice = createSlice({
    name: 'hashtags',
    initialState: {
        resMsg: '',
        hashtagData: null,
        hashtagList: null,
        isLoading: false,
        isDeleted: false,
        isCreated: false,
        isUpdated: false,
        total_count: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHashtags.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
            })
            .addCase(fetchHashtags.fulfilled, (state, { payload }) => {
                state.hashtagList = payload?.result?.rows;
                state.total_count = payload?.result?.count;
                state.isLoading = false;
            })
            .addCase(fetchHashtags.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(hashtagCreation.pending, (state) => {
                state.isLoading = true;
                state.isCreated = false;
                state.resMsg = '';
            })
            .addCase(hashtagCreation.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isCreated = payload?.success;
            })
            .addCase(hashtagCreation.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isCreated = false;
            })
            .addCase(deleteHashtags.pending, (state) => {
                state.isLoading = true;
                state.resMsg = '';
                state.isDeleted = false;
            })
            .addCase(deleteHashtags.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isDeleted = payload?.success;
            })
            .addCase(deleteHashtags.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.resMsg = payload?.message;
                state.isDeleted = false;
            });
    },
});

export default hashtagSlice.reducer;
